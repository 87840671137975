<template>
  <div style="text-align: center;">
    <van-list>
      <van-image
        style="margin: 25% 35% 3% 35%;"
        width="5rem"
        height="5rem"
        fit="cover"
        :src="newImg"
      />
      <span style="margin: auto 35% auto 35%;">支付成功!</span>
      <van-button
        type="primary"
        style="margin: 15% 5% auto 5% ; width: 90%; height: 40%;"
        color="#57c4b7"
        round
        size="large"
        @click="onEnter"
      >确定</van-button>
    </van-list>
  </div>
</template>
<script>
// import { Toast } from "vant";
// import ajax from "../../lib/ajax";
// import store from '../../store';
// const OperatorId = "100002";
export default {
  data() {
    return {
      newImg: require("../../assets/PaySuccess.png")
    };
  },
//   created() {
//     this.onload();
//   },
  methods: {
    // async onload() {
    //   Toast.loading({
    //     message: "支付中...",
    //     forbidClick: true,
    //     loadingType: "spinner",
    //     duration: 0
    //   });
    //   let orderId = sessionStorage.getItem("orderid");
    //   let depname = sessionStorage.getItem("depname"); //声明科室名称
    //   let dorname = sessionStorage.getItem("dorname"); //声明医生姓名
    //   let regNo = sessionStorage.getItem("regNo");
    //   let seeTime = sessionStorage.getItem("seeTime");
    //   await ajax
    //     .post("/Api/WechatAgentService/AgentRegistPaid", {
    //       orderId: orderId,
    //       regNo: regNo,
    //       termialType: null,
    //       payWay: 3,
    //       hisFromDept: depname,
    //       hisFromDoctor: dorname,
    //       hisFromCreateTime: seeTime,
    //       operatorId: OperatorId,
    //       machineName: "wechat"
    //     })
    //     .then(res => {
    //       if (res.data.result === 0) {
    //         Toast.clear();
    //         Toast("挂号成功，请按照预约时间进行就诊");
    //       } else if (res.data.result === -1) {
    //         Toast.clear();
    //         Toast("挂号失败，订单不存在");
    //       } else {
    //         Toast.clear();
    //         Toast("挂号失败，出现未知错误：" + res.data.result);
    //       }
    //     })
    //     .catch(err => {
    //       Toast.clear();
    //       Toast("出现未知错误：" + err);
    //     });
      // Dialog.alert({
      //     message:"orderId:"+orderId+"openId:"+openId
      // +"userName:"+userName+"depname:"+depname
      // +"dorname:"+dorname+"regNo:"+regNo,
      // });
      // 异步更新订单状态
      //   let times = 21;
      //   let timer = setInterval(() => {
      //     setTimeout(() => {
      //       if (times > 0) {
      //         ajax
      //           .get("/Api/PayService/QueryOrderPayState?input=" + orderId)
      //           .then(res => {
      //             type = res.data.result.state;
      //             // alert(JSON.stringify(type));
      //             // 数据全部加载完成
      //             if (type === 2) {
      //               times = 0;
      //               clearTimeout(timer);
      //               Toast.clear();
      //               ajax
      //                 .post("/Api/RegistrationService/Paid", {
      //                   orderId: orderId,
      //                   regNo: regNo,
      //                   termialType: null,
      //                   payWay: 3,
      //                   hisFromDept: depname,
      //                   hisFromDoctor: dorname,
      //                   hisFromCreateTime: seeTime,
      //                   machineName: "wechat",
      //                   operatorId: OperatorId
      //                 })
      //                 .then(res => {
      //                   let rep = res.data.result;
      //                   if (rep.success) {
      //                     ajax.post("/Api/DummyAppService/RegistSuccess", {
      //                       openId: openId,
      //                       userName: userName,
      //                       deptName: depname,
      //                       doctorName: dorname
      //                     });
      //                   } else {
      //                     Toast("失败：" + rep.msg);
      //                   }
      //                 })
      //                 .catch(err => {
      //                   Toast("错误：" + err);
      //                 });
      //             }
      //           })
      //           .catch(err => {
      //             console.log(err);
      //           });
      //         --times;
      //       } else {
      //         clearTimeout(timer);
      //         Toast.clear();
      //         Toast("此次交易已超时，款项将退回");
      //         ajax
      //           .get(
      //             "/Api/RegistrationService/AbortReg?RegNo=" +
      //               regNo +
      //               "&OperatorId=" +
      //               OperatorId
      //           )
      //           .then(async res => {
      //             console.log(res.data.result);
      //             if (res.data.result > 0) {
      //               Toast("退款正在受理中，请注意查收");
      //               this.onload();
      //             } else if (res.data.result === -1) {
      //               Toast("订单不存在");
      //             } else if (res.data.result === -2) {
      //               Toast("his返回失败");
      //             } else {
      //               Toast("申请退款失败，请到窗口详询");
      //             }
      //           })
      //           .catch(err => {
      //             console.log(err.message);
      //             Toast(err.message);
      //           });
      //       }
      //     }, 0);
      //   }, 3000);
    // },
    onEnter() {
      this.$router
        .replace({
          path: "/CardLs"
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>